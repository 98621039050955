import * as _ from "lodash";
import { IFormElement } from "../../interface/IFormElement";
import {
    UI_ELEMENT_TYPES
} from "../../../config";
import { IOption } from "../../interface/IOption";
import { DateUtil } from "./Date";
import { catchError } from "../../../../../helpers/ErrorHandler.component";

// Utility helper
export class UtilHelper{

    public dateUtil: DateUtil;
    public static allOptionLbl = "All";
    public static allOptionValue = "all";
    public static allOptionLblVal: IOption =  {
        label:"All",
        value:"all"
    };

    constructor(){
        this.dateUtil = new DateUtil();
    }

    /**
     * Check if the element value is empty or not
     *
     * @param {IFormElement} element.
     * @param {any} elementValue.
     * @return boolean.
     */
    isEmptyElementValue = (element: IFormElement, elementValue:any): any=>{
        let isEmpty = true;
        switch(element.type_id){
        // Text box
        case UI_ELEMENT_TYPES.TEXT_INPUT:
            // Checkbox
            /* falls through*/
        case UI_ELEMENT_TYPES.CHECKBOX_INPUT:
            // Radio 
            /* falls through*/
        case UI_ELEMENT_TYPES.RADIO_INPUT:
            isEmpty = _.isEqual((elementValue || "").toString().trim().length, 0);
            break;
            // Multiselect
        case UI_ELEMENT_TYPES.SELECT_INPUT:
            if(element.is_multi){
                isEmpty = _.isNull(elementValue) || _.isEqual((elementValue || []).length, 0);
            }else{
                isEmpty = _.isNull(elementValue);
            }
            break;
		// Thubmnail
		case UI_ELEMENT_TYPES.THUMBNAIL:
			isEmpty = _.isNull(elementValue);
				break;
            // Datetime input
        case UI_ELEMENT_TYPES.DATETIME_INPUT:
            isEmpty = _.isNull(elementValue) || _.isEmpty(elementValue)
                || _.isUndefined(elementValue);
            break;
            //DateRange Picker
        case UI_ELEMENT_TYPES.DATERANGE_INPUT:{
            const RangeValue = elementValue || null;
            if(_.isNull(RangeValue)){
                isEmpty =  true;
            }else{
                isEmpty = _.isNull(RangeValue[0]) && _.isNull(RangeValue[1]);
            }
            break;
        }		
        //File input
        case UI_ELEMENT_TYPES.FILE_INPUT:
            isEmpty = _.isNull(elementValue) || (_.isEmpty(elementValue.acceptedFiles) && _.isEmpty(elementValue.rejectedFiles));
            break;
        
        case UI_ELEMENT_TYPES.GRID:
            isEmpty = _.isNull(elementValue) || (_.isEqual(elementValue?.length,0));
            break;
        }
        return isEmpty;
    }

    
    /**
     * Get element value
     *
     * @param {IFormElement} element.
     * @return boolean.
     */
      getElementValue = (element: IFormElement) : any=>{
          if(this.isEmptyElementValue(element, element.value)){
              return null;
          }
          let elementValue = element.value;

          if(_.isEqual(element.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)){
            if(element.is_multi){
                elementValue = element.value.map((ele: IOption) => (ele.value));
            }else{
                // Single Select
                elementValue = element.value.value;
            }
          }
		  else if(_.isEqual(element.type_id, UI_ELEMENT_TYPES.THUMBNAIL)){
            elementValue = element.value.id;
          }
          return elementValue;
      }

     /**
     * Check if the input is a valid number
     *
     * @param {any} number.
     * @return number | null.
     */
    getValidNumber = (number: any, formElement: IFormElement): any =>{

        if(formElement.is_decimal){
            return this.getValidDecimalNumber(number);
        }

        const InputNumber = (number || "").toString().trim();
        return /^\d+$/.test(InputNumber) ? InputNumber : null;
    }

     /**
     * Count decimal numbers
     *
     * @param {any} number.
     * @return number | null.
     */
      getDecimalNumbers = (number: any): any =>{

        if (_.isEqual(Math.floor(number), number)) return 0;

        const str = (number || "").toString();
        if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
            return str.split("-")[1] || 0;
        } else if (str.indexOf(".") !== -1) {
            return str.split(".")[1].length || 0;
        }
        return str.split("-")[1] || 0;
    }

     /**
     * Get decimal number
     *
     * @param {any} number.
     * @return number | null.
     */
      getDecimalNumber = (number: any, decimalPlaces: number): any =>{
        return parseFloat(parseFloat(number).toFixed(decimalPlaces));
    }

    /**
     * Check if the input is a valid decimal number
     *
     * @param {any} number.
     * @return number | null.
     */
    getValidDecimalNumber = (number: any): any =>{
        const InputNumber = (number || "").toString().trim();
        return /^\d*(\.\d+)?$/.test(InputNumber) ? InputNumber : null;
    }
    /**
     * Find if the value is empty
     *
     * @param {any} value.
     * @return boolean.
     */
    isEmptyValue = (value:any) : any=>{
        const Value = (value || "").toString().trim();
        return _.isEqual(Value.length, 0);
    }

    /**
     * Find value in Options datasource
     *
     * @param {IOption[]} dataSource.
     * @param {any} value.
     * @return number | null.
     */
    findValueInDS = (dataSource:IOption[], value: any): any=>{
        for(const ds of dataSource){
            if(_.isEqual(ds.value, value)){
                return ds;
            }
        }
        return null;
    }
    /**
     * Get form element value for review form
     *
     * @param {IFormElement} formElement.
     * @return any | null.
     */
    getElementValueForReviewForm = (formElement: IFormElement): any =>{
        switch (formElement.type_id) {
        case UI_ELEMENT_TYPES.TEXT_INPUT:
        case UI_ELEMENT_TYPES.DATETIME_INPUT:{
            return (formElement.value || "").toString().trim();
        }
        case UI_ELEMENT_TYPES.CHECKBOX_INPUT: {
            if(_.isNull(formElement.value)){
                return null;
            }
            const IsAll = (formElement.value || "").indexOf(UtilHelper.allOptionValue);

            if(!_.isEqual(IsAll, -1)){
                return UtilHelper.allOptionLbl;
            }

            const Values = (formElement.value).split(",").map((value: any)=>{
                const DSValue = this.findValueInDS(formElement.dataSource, value);
                return _.isNull(DSValue) ? null : DSValue.label;
            }).filter((value: any) => !_.isNull(value));
            return Values.join(",");
        }
        case UI_ELEMENT_TYPES.RADIO_INPUT:{
            if(_.isNull(formElement.value)){
                return null;
            }
            const DSValue = this.findValueInDS(formElement.dataSource, formElement.value);
            return DSValue?.label;
        }
        case UI_ELEMENT_TYPES.SWITCH_INPUT:
            return formElement.value ? "Yes" : "No";
        case UI_ELEMENT_TYPES.DATERANGE_INPUT:
            if(!_.isNull(formElement.value)){
                const StartDate = this.dateUtil.formatDateOrTime(formElement.value[0],
                    {
                        ...formElement,
                        show_time: false
                    });
                const EndDate = this.dateUtil.formatDateOrTime(formElement.value[1],
                    {
                        ...formElement,
                        show_time: false
                    });
                return `${StartDate} - ${EndDate}`;
            }
            return null;
        case UI_ELEMENT_TYPES.SELECT_INPUT:
            if(!_.isNull(formElement.value)){
                    
                if(formElement.is_multi){
                    const ElementValues = (formElement.value || []);
                    return ElementValues.map((option: IOption) => option.label).join(", ");
                }
                return formElement.value.label;
            }
            return null;
        case UI_ELEMENT_TYPES.FILE_INPUT:
            if(!_.isNull(formElement.value) && !_.isEmpty(formElement.value.acceptedFiles)){
                return formElement.value.acceptedFiles;
            }
            return null;
         case UI_ELEMENT_TYPES.GRID:{
            if(_.isNull(formElement.value)){
                return null;
            }
                const DSValue = formElement.dataSource;
                return DSValue;
            }
        default:
            return null;
        }
    }

    converMBtoBytes = (max_range: any): any =>{
        return 1048576 * max_range;
    }
 /**
     * Convert number to Thousands, Million, and Billions
     *
     * @param {number} value.
     * @return any.
     */
    numberToKMB = (value: number): any =>{
        const Value = Math.abs(Number(value));
        let numberKB: any = Value;
        if(_.gte(Value, 1.0e+9)){
            numberKB = Value / 1.0e+9 + "B";
        } else if(_.gte(Value,1.0e+6)){
            numberKB = Value / 1.0e+6 + "M";
        }else if(_.gte(Value,1.0e+3)){
            numberKB = Value / 1.0e+3 + "K";
        }
        return numberKB;
    }

    /**
     * Extract error message from response
     *
     * @param {object} error.
     * @return string.
     */
    getErrorMessage(error: any): string{
        const DefaultErrorMessage = "Please try again later. If the error persists, please contact the support at <a href=\"mailTo:gdt-automations-support@groupm.tech\">gdt-automations-support@groupm.tech</a>.";
        if (!error.response) {
            return DefaultErrorMessage;
        }
        const errorMessage = catchError(error);

        return _.isEqual(
            (errorMessage || "").toString().trim().length,
            0
        )
            ? DefaultErrorMessage
            : errorMessage;
    }

	/**
     * Validate Object Properties
     *
     * @param inputValue.
     * @param keys.
     * @return boolean.
     */
	 validateObjectProperties(inputValue: any, keys: string[]): any{
		 if(_.isNull(inputValue)){
			 return false;
		 }
        if(_.isEqual(typeof inputValue, "string")){
			return false;
		}
		if(_.isEqual(typeof inputValue, "object")){
			let isValid = true;
			keys.forEach((key: string)=>{
				isValid = isValid && key in inputValue;
			});
			return isValid;
		}
		return false;
    }
}