import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ToolFormComponent from "../../../../../UI/Elements/Forms";
import UIGrid from "./Grid";
import { GAEvent } from "../../../../../helpers/GA";
import { GOOGLE_ANALYSTICS_ACTIONS } from "../../../../../helpers/GA/GoogleAnalysticsUtils";
import { ITool } from "../../../../../UI/Elements/Forms/interface/ITool";
import { AuthElement } from "../../../../../UI/Elements/Forms/helper/Forms/BuildForm/Auth";
import { BuildNewFormHelper } from "../../../../../UI/Elements/Forms/helper/Forms/BuildForm";
import { catchError, getErrorStatusCode } from "../../../../../helpers/ErrorHandler.component";
import _ from "lodash";
import { GET_GOOGLE_REFRESH_TOKEN_URL, TOOL_SIGNOUT, GET_TOOL, SIGNOUT_BUTTON_USER_INFO } from "../../../../../api/paths";
import { Axios } from "../../../../../api/service/Axios";
import { DefaultForm } from "../../../../../UI/Elements/Forms/Type/default";
import { CircularProgress } from "../../../../../UI/Progress";
import { LoginAuthButton } from "../../../../../UI/Elements/Forms/Auth";
import SnackbarAlert from "../../../../../UI/Snackbar/SnackbarAlert.component";
import { AccessComponent } from "../../../../../UI/Access";
import { IFormElementAuth } from "../../../../../UI/Elements/Forms/interface/IFormElementAuth";
import { getForm } from "./Helpers/Forms/CloneHelper";

type props = any;

interface IData {
  action: string;
}


type ESnackbarAlert = "success" | "error" | "info" | "warning";

// Internal tool box page component
export const InternalTool = (props: props): React.JSX.Element => {
  const { toolParams, toolFormData } = props;
  const history = useHistory();
  const [showForm, setShowForm] = useState(!toolFormData.is_grid);
  const bundle = useSelector((state: any) => state.initial.bundle);
  const [authorizedToolData, setauthorizedToolData] = useState<ITool | any>({});
  const [tool, setToolData] = useState<ITool | any>({});
  const [signoutButtonUserInfo, setSignoutButtonUserInfo] = useState<any>({ message: "", type: [] });
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [unAuthError, setUnAuthError] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: "success",
  });

  // Set Snackbar Alerts
  const setAlert = (message: string, variant: ESnackbarAlert): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant,
      message,
    });
  };

  const DefaultErrorMessage = `Request submission failed. Please try again later. If the error persists, please contact the support at <a href="mailTo:${process.env.REACT_APP_SUPPORT_DL}">${process.env.REACT_APP_SUPPORT_DL}</a>.`;

  const getData = (is_Edit: boolean, AuthGroups: any, UpdatedForms: any): any => {
    return {
      ...props.toolFormData,
      initialForms: AuthGroups.forms,
      forms: UpdatedForms,
      datasetForm: UpdatedForms,
      emptyForm: UpdatedForms,
      authGroups: AuthGroups.authGroups || [],
      is_Edit: is_Edit
    };

  };

  const DataLoad = async (Form: any, is_Edit: any): Promise<void> => {
    setInitialLoading(true);
    setError(false);
    try {
      const buildNewForm = new BuildNewFormHelper();
      const authElement = new AuthElement();
      const AuthGroups =
        (await authElement.getAuthGroups(Form, props.toolId)) ||
        [];

      if (
        !_.isEqual(AuthGroups.authGroups.length, 0) &&
        authElement.isAnyAuthGroupIsNull(AuthGroups.authGroups)
      ) {
        const ToolData: ITool = {
          ...props.toolFormData,
          initialForms: props.toolFormData.forms,
          emptyForm: [],
          datasetForm: [],
          forms: [],
          authGroups: AuthGroups.authGroups || [],
        };

        const errorMessage = authElement.isAnyAuthGroupHasError(
          AuthGroups.authGroups
        );
        if (!_.isEmpty(errorMessage)) {
          setAlert(errorMessage, "error");
        }
        setToolData(ToolData);
        return setInitialLoading(false);
      }

      const UpdatedForms = await buildNewForm.form(AuthGroups.forms);
      const Data = getData(is_Edit, AuthGroups, UpdatedForms);
      setToolData(Data);
      return setInitialLoading(false);
    } catch (err: any) {
      setInitialLoading(false);
      const StatusCode = getErrorStatusCode(err);
      if (!_.isNull(StatusCode) && _.isEqual(StatusCode, 403)) {
        setUnAuthError(true);
        setError(true);
        return;
      }
      const errorMessage = catchError(err);
      setAlert(errorMessage, "error");
      setError(true);
    }
  };

  const onAdd = (): void => {
    const is_Edit = false;
    setToolData(authorizedToolData);
    DataLoad(authorizedToolData.forms, is_Edit);
    setShowForm(true);
  };

  const onCloneChange = (PortalData: any, jobName:string): void => {
    const is_Edit = false;
    const updatedForm = getForm(tool.forms, PortalData, jobName);

    setToolData((previousData: any) => ({
      ...previousData,
      forms: updatedForm
    }));
    DataLoad(updatedForm, is_Edit);
    setShowForm(true);
  };

  const onEditChange = (PortalData: any, rowJobId: any, jobName: string): void => {
    const is_Edit = true;
    const updatedForm = getForm(tool.forms, PortalData, jobName);

    setToolData((previousData: any) => ({
      ...previousData,
      forms: updatedForm,
    }));
    DataLoad(updatedForm, is_Edit);
    setJobId(rowJobId);
    setShowForm(true);
  };

  const getSignoutButtonUserInfo = async (authInfo: any): Promise<any> => {
    const tokenInfo: any = [];
    setLoading(true);
    const typeArray: any = [];
    await authInfo.map((authObj: any) => {
      typeArray.push(authObj.type);
      if (!_.isNull(authObj.value)) {
        if (!_.isEqual(authObj.value?.length, 0)) {
          const info: any = { "type": authObj.type, "value": authObj.value, "app_id": authObj.app_id, "type_id": authObj.type_id };
          tokenInfo.push(info);
        }
      }
    });
    setSignoutButtonUserInfo((prevData: any) => ({ ...prevData, type: typeArray }));
    if (typeArray.includes("Google") && _.isEqual(typeArray.length, 1)) {
      await Axios.post(SIGNOUT_BUTTON_USER_INFO, { "token_info": tokenInfo })
        .then((response) => {
          setSignoutButtonUserInfo((prevData: any) => ({ ...prevData, message: response.data.message }));
          setLoading(false);
        }).catch((err) => {
          setLoading(false);
          const errorMessage = catchError(err);
          setAlert(errorMessage, "error");
          setError(true);
        });
    } else { setLoading(false); }
  };

  const InitialDataLoad = async (toolFormInitialData: any, toolId: any): Promise<void> => {
    setInitialLoading(true);
    try {
      const authElement = new AuthElement();
      const Forms = toolFormInitialData.forms;
      const buildNewForm = new BuildNewFormHelper();
      const AuthGroups =
        (await authElement.getAuthGroups(Forms, toolId)) || [];
      if (
        !_.isEqual(AuthGroups.authGroups.length, 0) &&
        authElement.isAnyAuthGroupIsNull(AuthGroups.authGroups)
      ) {
        const ToolData: ITool = {
          ...toolFormInitialData,
          initialForms: Forms,
          emptyForm: [],
          datasetForm: [],
          forms: [],
          authGroups: AuthGroups.authGroups || [],
          is_Edit: false
        };
        setauthorizedToolData(ToolData);
        setToolData(ToolData);
        return setInitialLoading(false);
      }
      const UpdatedForms = await buildNewForm.form(AuthGroups.forms);
      const Data: ITool = {
        ...toolFormInitialData,
        initialForms: AuthGroups.forms,
        forms: UpdatedForms,
        datasetForm: UpdatedForms,
        emptyForm: UpdatedForms,
        authGroups: AuthGroups.authGroups || [],
        is_Edit: false
      };
      await getSignoutButtonUserInfo(AuthGroups.authGroups);
      setauthorizedToolData(Data);
      setToolData(Data);
      return setInitialLoading(false);
    }
    catch (err: any) {
      setInitialLoading(false);
      const StatusCode = getErrorStatusCode(err);
      if (!_.isNull(StatusCode) && _.isEqual(StatusCode, 403)) {
        setUnAuthError(true);
        setError(true);
        return;
      }
    }
  };

  useEffect(() => {
    InitialDataLoad(props.toolFormData, toolParams.toolId);
  }, []);

  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const CatchSocialAPIsError = (err: any): void => {
    setLoading(false);
    let errorMessage = err;
    if (!_.isUndefined(err.response) && !_.isUndefined(err.response.headers)) {
      errorMessage = err.response.headers["error-message"];
      if (_.isUndefined(errorMessage)) {
        errorMessage = catchError(err);
      }
    }
    const Message = _.isEqual((errorMessage || "").toString().trim().length, 0)
      ? DefaultErrorMessage
      : errorMessage;
    setAlert(Message, "error");
  };

  const onFbResponse = async (
    response: any,
    typeId: any,
    tokenapiurl: any
  ): Promise<any> => {
    if (!_.isUndefined(response.accessToken)) {
      setLoading(true);
      const buildNewForm = new BuildNewFormHelper();
      const authElement = new AuthElement();
      try {
        const Response = await Axios.post(tokenapiurl, {
          accessToken: response.accessToken,
          typeId: typeId,
          toolId: tool?.tool_id,
        });
        let UpdatedForms = authElement.updateFormsWithToken(
          tool?.initialForms,
          typeId,
          Response.data.access_token
        );

        UpdatedForms = await buildNewForm.form(UpdatedForms);
        const AuthGroups = await authElement.getAuthGroups(
          UpdatedForms,
          tool?.tool_id
        );
        await getSignoutButtonUserInfo(AuthGroups.authGroups);

        authorizedToolDatacall(AuthGroups);

        setLoading(false);
        return setToolData((previousData: any) => ({
          ...previousData,
          forms: AuthGroups.forms,
          datasetForm: AuthGroups.forms,
          initialForms: AuthGroups.forms,
          emptyForm: AuthGroups.forms,
          authGroups: AuthGroups.authGroups,
        }));
      } catch (err) {
        CatchSocialAPIsError(err);
      }
    }
  };

  const authorizedToolDatacall =(AGroups:any):void=>{
    setauthorizedToolData((prev: any) => ({
      ...prev,
      forms: AGroups.forms,
      datasetForm: AGroups.forms,
      initialForms: AGroups.forms,
      emptyForm: AGroups.forms,
      authGroups: AGroups.authGroups,
    }));
  };
  const onGoogleResponse = async (response: any, typeId: any): Promise<any> => {
    if (!_.isUndefined(response.code)) {
      setLoading(true);
      const buildNewForm = new BuildNewFormHelper();
      const authElement = new AuthElement();
      try {
        const Response = await Axios.post(GET_GOOGLE_REFRESH_TOKEN_URL, {
          authorizationCode: response.code,
          typeId: typeId,
          toolId: tool?.tool_id,
        });
        let UpdatedForms = authElement.updateFormsWithToken(
          tool?.initialForms,
          typeId,
          Response.data.refresh_token
        );
        UpdatedForms = await buildNewForm.form(UpdatedForms);
        const AuthGroups = await authElement.getAuthGroups(
          UpdatedForms,
          tool?.tool_id
        );
        await getSignoutButtonUserInfo(AuthGroups.authGroups);

        setauthorizedToolData((previousData: any) => ({
          ...previousData,
          forms: AuthGroups.forms,
          datasetForm: AuthGroups.forms,
          authGroups: AuthGroups.authGroups,
        }));

        setLoading(false);
        return setToolData((previousData: any) => ({
          ...previousData,
          forms: AuthGroups.forms,
          initialForms: AuthGroups.forms,
          datasetForm: AuthGroups.forms,
          authGroups: AuthGroups.authGroups,
        }));
      } catch (err: any) {
        CatchSocialAPIsError(err);
      }
    }
  };

  const handleBackButton = (data: IData): void => {
    GAEvent(
      toolFormData.tool_name,
      GOOGLE_ANALYSTICS_ACTIONS.BUTTON_CLICKED,
      data.action
    );
    if (toolFormData.is_grid && showForm) {
      setShowForm(false);
      return;
    }
    history.push("/");
  };

  const handleSignoutButton = async (toolId: number): Promise<any> => {
    setLoading(true);
    try {
      const response: any = await Axios.remove(TOOL_SIGNOUT(toolId));
      if (_.isEqual(response.status, 200)) {
        const ResponseInitialData: any = await Axios.get(
          GET_TOOL(props.toolFormData.tool_id, props.toolFormData.tool_name, props.toolFormData.tool_version)
        );
        if (_.isEqual(ResponseInitialData.status, 200)) {
          InitialDataLoad(ResponseInitialData.data, props.toolId);
        }
      }
      setLoading(false);
    } catch (err) {
      const errorMessage = catchError(err);
      setAlert(errorMessage, "error");
      setLoading(false);
    }
  };

  const onSubmitChange = (message: string): void => {
    if (toolFormData.is_grid && showForm) {
      setShowForm(false);
      setSnackbar({
        ...snackbar,
        open: true,
        message: message
      });
    }
  };

  const BACK_BTN_TITLE = toolFormData.is_grid
    ? "Back to Grid"
    : bundle.MENU_TOOLKIT_TITLE;

  if (initialLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Fragment>
        <SnackbarAlert
          open={snackbar.open}
          message={snackbar.message}
          variant={snackbar.variant}
          onOpenCloseAlert={handleCloseAlert}
        />
        {unAuthError && (
          <AccessComponent
            heading={bundle.UNAUTH_TOOL_HEADING}
            description={bundle.UNAUTH_TOOL_DESCRIPTION}
          />
        )}
      </Fragment>
    );
  }

  if (!_.isEmpty(tool?.authGroups)) {
    const authElement = new AuthElement();

    if (authElement.isAnyAuthGroupIsNull(tool?.authGroups)) {
      const AuthGroups: IFormElementAuth[] = tool?.authGroups || [];

      return (
        <Fragment>
          {loading && <CircularProgress />}
          <DefaultForm
            tool={tool}
            handleBackButton={handleBackButton}
            authComponent={
              <Fragment>
                <LoginAuthButton
                  authGroups={AuthGroups}
                  onFbResponse={onFbResponse}
                  onGoogleResponse={onGoogleResponse}
                />
                <SnackbarAlert
                  open={snackbar.open}
                  message={snackbar.message}
                  variant={snackbar.variant}
                  onOpenCloseAlert={handleCloseAlert}
                />
              </Fragment>
            }
          />
        </Fragment>
      );
    }
  }

  if (!showForm) {
    return (
      <>
        {loading && <CircularProgress />}
        <UIGrid
          toolFormData={toolFormData}
          handleBackButton={handleBackButton}
          onAdd={onAdd}
          onCloneChange={onCloneChange}
          onEditChange={onEditChange}
          authComponent={
            <Fragment>
              <SnackbarAlert
                open={snackbar.open}
                message={snackbar.message}
                variant={snackbar.variant}
                onOpenCloseAlert={handleCloseAlert}
              />
            </Fragment>
          }
          signoutConfig={{
            tool_id: tool.tool_id,
            is_signout_button: !_.isEmpty(tool?.authGroups),
            user_info: signoutButtonUserInfo,
            signout_action: handleSignoutButton,
          }}
        />
      </>
    );
  }

  return (
    <>
      {loading && <CircularProgress />}
      <ToolFormComponent
        backBtnTitle={BACK_BTN_TITLE}
        handleBackButton={handleBackButton}
        {...toolParams}
        toolData={tool}
        toolFormData={props.toolFormData}
        jobId={jobId}
        onSubmitChange={onSubmitChange}
        signoutConfig={{
          tool_id: tool.tool_id,
          is_signout_button: !_.isEmpty(tool?.authGroups),
          user_info: signoutButtonUserInfo,
          signout_action: handleSignoutButton,
        }}
      />
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        variant={snackbar.variant}
        onOpenCloseAlert={handleCloseAlert}
      />
    </>
  );
};
