import React, { Fragment } from "react";
import * as _ from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { UIInput } from "../../../FormElements/Input";
import { IForm } from "../../interface/IForm";
import { IFormGroup } from "../../interface/IFormGroup";
import { IFormElement } from "../../interface/IFormElement";
import style from "./style";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  form: IForm;
  onChange: any;
  onValidateForms: any;
  loading: boolean;
  ishavingParent: any;
};

// FormBuilder Component
export const FormBuilder = (props: props): JSX.Element => {
  const { form, onChange, onValidateForms, loading, ishavingParent } = props;
  const classes = useStyles();

  const FormGroups = _.orderBy(form.groups, ["display_order"], ["asc"]).map(
    (formGroup: IFormGroup) => {
      const GroupTitle = (formGroup.title || "").toString().trim();
      const FormElements: IFormElement[] = _.orderBy(
        formGroup.elements,
        ["display_order"],
        ["asc"]
      );
      return (
        <div className={classes.formGroup} key={formGroup.id}>
          {!_.isEqual(GroupTitle.length, 0) && (
            <header className={classes.sectionTitle}>{formGroup.title}</header>
          )}
          {FormElements.map((formElement: IFormElement) => (
            <UIInput
              key={formElement.id}
              formId={form.id}
              formGroupId={formGroup.id}
              onChange={onChange}
              onValidateForms={onValidateForms}
              formElement={formElement}
              form={form}
              loading={loading}
              ishavingParent={ishavingParent}
            />
          ))}
        </div>
      );
    }
  );

  return <Fragment>{FormGroups}</Fragment>;
};
