import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import * as _ from "lodash";
import { FormBuilder } from "../../builder";
import { ReviewForm } from "./ReviewForm";
import style from "./style";
import { ToolInfo } from "../../../../../../components/Toolkit/Tool/Type/Layout";
import { ITool } from "../../../interface/ITool";
import { IForm } from "../../../interface/IForm";
import { UIOutlineButton } from "../../../../../Buttons";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  tool: ITool;
  onChange: any;
  onSubmit: any;
  onValidateForms: any;
  handleNextBack: any;
  handleReset: any;
  activeStep: number;
  handleNewForm: any;
  goToStep: any;
  handleBackButton: any;
  loading:boolean;
  ishavingParent:any;
};

export const StepperForm = (props: props): JSX.Element => {
  const {
    tool,
    onChange,
    onValidateForms,
    handleNextBack,
    handleReset,
    activeStep,
    onSubmit,
    handleNewForm,
    goToStep,
    handleBackButton,
    loading,
    ishavingParent
  } = props;
  const classes = useStyles();

  const propsToToolInfo = {
    data: { ...tool },
  };
  const Forms = tool.forms.filter((form: IForm) => form.isVisible);
  let disabledBackButton = _.isEqual(activeStep, 0);

  if (_.lt(activeStep, Forms.length)) {
    disabledBackButton = disabledBackButton || Forms[activeStep].loading;
  }
  let formData;
  if (_.isEqual(activeStep, Forms.length + 1)) {
    formData = (
      <div className={classes.toolContent}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleNewForm();
            return handleReset();
          }}
        >
          New Form
        </Button>
        <UIOutlineButton
          cssStyle={{
            marginLeft: "0.75rem",
          }}
          isOutline={true}
          onClick={() => {
            handleBackButton({
              action: "Go back",
            });
          }}
          btnText="Go back"
        />
      </div>
    );
  } else {
    let formStep;
    if (_.isEqual(activeStep, Forms.length)) {
      formStep = <ReviewForm forms={Forms} goToStep={goToStep} />;
    } else {
      formStep = (
        <FormBuilder
          form={Forms[activeStep]}
          onValidateForms={onValidateForms}
          onChange={onChange}
          loading={loading}
          ishavingParent={ishavingParent}
        />
      );
    }

    let submitBtnTxt;
    if (_.isEqual(activeStep, Forms.length)) {
      submitBtnTxt = "Submit";
    } else {
      submitBtnTxt = "Next";
    }
    formData = (
      <div className={classes.toolContent}>
        {formStep}

        <div>
          <UIOutlineButton
            cssStyle={{
              marginRight: "0.75rem",
            }}
            disabled={disabledBackButton}
            isOutline={true}
            onClick={() => handleNextBack(-1)}
            btnText="Back"
          />
          {_.isEqual(activeStep, Forms.length) ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                onSubmit(tool.forms, (isError: boolean) => {
                  if (isError) {
                    return;
                  }
                  return handleNextBack(1);
                })
              }
              className={classes.button}
            >
              Submit
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNextBack(1)}
              className={classes.button}
              disabled={!Forms[activeStep].isValid || Forms[activeStep].loading}
            >
              {submitBtnTxt}
            </Button>
          )}
          <UIOutlineButton
            cssStyle={{
              marginLeft: 4,
            }}
            isOutline={true}
            onClick={() => {
              handleBackButton({
                action: "Cancel",
              });
            }}
            btnText="Cancel"
          />
        </div>
      </div>
    );
  }

  return (
    <Grid container className={classes.toolContentWrapper}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        xl={8}
        className={classes.infoWrapper}
      >
        {formData}
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        justifyContent="center"
        className={classes.infoWrapper}
      >
        <ToolInfo {...propsToToolInfo} />
      </Grid>
    </Grid>
  );
};
