import React, { useState, useRef } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import { Grid } from "@material-ui/core";
import { UILabel } from "../../Child/Label";
import style from "../style";
import { IFormElement } from "../../../Forms/interface/IFormElement";
import { UIDataGrid } from "../../DataGrid";
import { UI_BTN_ACTIONS } from "../../../config";
import { ConfirmationDialog } from "../../../../Dialogs/Confimation";
import { UIOutlineButton } from "../../../../Buttons/Types/Outline";
import Utility from "./Utility";
import { UIFileError } from "../../../../Error/File";
import { UIFileComponent } from "./FileComponent";
import { IFile } from "../../../Forms/interface/File/IFile";
import { FileHelper } from "../../../Forms/helper/Utils/FileUtil/File";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
};

// UI File Upload Component for Grid
export const UIGridFileUpload = (props: props): JSX.Element => {
  const { formElement, onChange, formId, formGroupId } = props;
  const [dialog, setDialog] = useState(false);
  const fileInputRef: any = useRef(null);
  const classes = useStyles();
  let errorMessage = "";

  if (formElement.apiError) {
    errorMessage = formElement.apiError;
  } else {
    errorMessage = formElement.touched ? formElement.error : "";
  }

  const onGridChange = (gridData: any): void => {
    onChange({
      formId,
      formGroupId,
      formElement,
      dynamicElement: {
        action: UI_BTN_ACTIONS.EDIT,
        value: gridData,
      },
      value: _.isNull(formElement.value) ? null : { ...formElement.value },
    });
  };

  const onDialogSubmit = (): void => {
    fileInputRef.current.click();
    setDialog(false);
  };

  const onDeleteFile = (name: string): void => {
    const Files: IFile[] = formElement.value.rejectedFiles.filter(
      (file: IFile) => !_.isEqual(file.name, name)
    );
    Utility.updateState(
      props,
      {
        action: UI_BTN_ACTIONS.UPLOAD,
        errorMessage: "",
        value: [],
      },
      {
        acceptedFiles: [],
        rejectedFiles: Files,
      }
    );
  };

  let rejectedFiles = [];

  if (!_.isNull(formElement.value)) {
    const fileHelper = new FileHelper();
    rejectedFiles = formElement.value.rejectedFiles.map((file: IFile) => {
      const FileName: any = file.name.split(".").slice(0, -1).join(".");
      const FileExtension: any = file.name.split(".").pop();

      return (
        <UIFileComponent
          key={file.name}
          fileName={`${fileHelper.breakFileName(FileName)}.${FileExtension}`}
          fileSize={fileHelper.formatBytes(file.size)}
          onDelete={() => onDeleteFile(file.name)}
        />
      );
    });
  }

  return (
    <Grid container item lg={12} md={12} sm={12} xs={12}>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <UILabel formElement={formElement} />
        </Grid>
        <Grid container alignItems="center" item lg={9} md={9} sm={12} xs={12}>
          <input
            type="file"
            onChange={(fileEvent: any) =>
              Utility.handleChange(fileEvent, props)
            }
            onClick={() => (fileInputRef.current.value = null)}
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".xlsx,.xls"
          />
          <UIOutlineButton
            isOutline={true}
            onClick={(): void => {
              if (_.isEqual(formElement.dynamic_fields_values.length, 0)) {
                fileInputRef.current.click();
                return;
              }
              setDialog(true);
            }}
            cssStyle={{
              textTransform: "none",
              fontWeight: 600,
            }}
            btnText="Upload File"
          />

          <div className={classes.gridUploadFile}>
            {!_.isNull(formElement.template_url) && (
              <a
                target="_blank"
                rel="noreferrer"
                className={classes.templateLink}
                href={formElement.template_url}
              >
                {formElement.template_label}
              </a>
            )}
          </div>
        </Grid>
        {dialog && (
          <ConfirmationDialog
            onClose={() => setDialog(false)}
            onSubmit={onDialogSubmit}
            heading="Overwrite existing data"
            body="Do you want to overwrite existing data?"
            cancelBtnText="Keep Data"
            submitBtnText="Continue"
            isDeleteAction={false}
          />
        )}
      </Grid>
      {!_.isEqual(rejectedFiles.length, 0) && (
        <Grid container direction="column" item lg={5} md={6} sm={12} xs={12}>
          {rejectedFiles}
        </Grid>
      )}

      {!_.isEqual(formElement.dynamic_fields_values.length, 0) && (
        <Grid container direction="column" item lg={12} md={12} sm={12} xs={12}>
          <UIDataGrid
            errorMessage={errorMessage}
            formElement={formElement}
            onChange={onGridChange}
          />
        </Grid>
      )}
      <div className={classes.fileErrorContainer}>
        {_.isEqual(formElement.dynamic_fields_values.length, 0) && (
          <UIFileError note={formElement.note} errorMessage={errorMessage} />
        )}
      </div>
    </Grid>
  );
};
