import { IFormGroup } from "../../Interface/IFormGroup";
import {
	ToolName,
	ToolVersion,
	ToolType,
	Channels,
	IsActive,
	ToolState,
	ToolStatus,
	ToolLabel,
	ToolPlatforms,
	Madeby,
	ShortDescription,
	LongDescription,
	CreatedBy,
	CreatedDate,
	ToolThumbnail,
	ToolScriptPath,
	ToolURL,
	ToolInfoURL,
	AuthLoginContent,
	FormSubmitAPIEndpoint,
	FormUpdateAPIEndpoint,
	IsFileDownloadAPI,
	LoadLastSavedData,
	ToolFormType,
	ModifiedBy,
	ModifiedDate,
	ToolLifeCycle,
	ToolAZGroups,
	IsMaintenance,
	ResetAuthorization,
	IsGrid,
	IsSchedule,
	IsServiceAccountAuth,
	ToolPersistButton
} from "./FormElements";


export const ToolGroup: IFormGroup = {
	id:"toolgroup",
	elements: [
		ToolName, 
		ToolVersion,
		IsActive, 
		IsMaintenance,
		ToolAZGroups,
		ShortDescription,
		LongDescription,
		ToolType,
		ToolLifeCycle,
		ToolStatus, 
		ToolLabel,
		ToolPlatforms,
		ToolState,
		ToolFormType,
		IsServiceAccountAuth,
		AuthLoginContent,
		FormSubmitAPIEndpoint,
		FormUpdateAPIEndpoint,
		IsFileDownloadAPI,
		IsGrid,
		IsSchedule,
		ToolPersistButton,
		LoadLastSavedData,
		Madeby,
		Channels,
		ToolScriptPath,
		ToolURL,
		ToolInfoURL,
		CreatedBy,
		CreatedDate,
		ModifiedBy,
		ModifiedDate,
		ResetAuthorization,
		ToolThumbnail,
	],
	isVisible: true,
	title: ""
};