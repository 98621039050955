import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import CheckIcon from "@material-ui/icons/Check";
import classNames from "classnames";
import style from "./style";
import { IForm } from "../../../interface/IForm";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  forms: IForm[];
  activeStep: number;
};

// Stepper Component
export const StepperHeader = (props: props): JSX.Element => {
  const { forms, activeStep } = props;
  const classes = useStyles();

  const Forms = forms
    .filter((form: IForm) => {
      return form.isVisible;
    })
    .map((form: IForm) => ({
      id: form.id,
      title: form.title,
    }));

  Forms.push({
    id: Forms.length + 1,
    title: "Review",
  });

  const Steps = Forms.map((form: any, index: number) => {
    const IsActiveStep = _.isEqual(activeStep, index);
    const IsCompletedStep = _.lt(index, activeStep);

    let stepIcon = <span />;

    if (IsCompletedStep) {
      stepIcon = <CheckIcon className={classes.checkIcon} />;
    } else if (IsActiveStep) {
      stepIcon = <div className={classes.stepNumberActiveChild}></div>;
    }

    return (
      <div
        key={form.id}
        className={classNames(classes.stepContainer, {
          [classes.stepContainerActive]: index <= activeStep,
        })}
      >
        <div className={classes.stepRoot}>
          <div
            className={classNames(classes.stepNumber, {
              [classes.stepNumberActive]: IsActiveStep,
              [classes.completedStep]: IsCompletedStep,
            })}
          >
            {stepIcon}
          </div>
          <div
            className={classNames(classes.stepName, {
              [classes.stepNameActive]: IsActiveStep,
            })}
          >
            {form.title}
          </div>
        </div>
      </div>
    );
  });

  return <div className={classes.container}>{Steps}</div>;
};
