import * as _ from "lodash";
import { IForm } from "../../../interface/IForm";
import { IFormElement } from "../../../interface/IFormElement";
import { IFormGroup } from "../../../interface/IFormGroup";
import {
    FormElementHelper
} from "../../Utils/Element";
import { UtilHelper } from "../../Utils";
import { IOption } from "../../../interface/IOption";
import {
    UI_AUTH_HEADER_NAMES,
    UI_DYNAMIC_CONFIG_ELEMENT_TYPES,
    UI_ELEMENT_AUTH_TYPES,
    UI_ELEMENT_TYPES
} from "../../../../config";
import * as ElementDS from "../../../../../ElementDS";
import { IGridHeader } from "../../../interface/Grid/IGridHeader";
import { IFileUpload } from "../../../interface/File/IFileUpload";
import { DateUtil } from "../../Utils/Date";

// Submit form
export class SubmitForm{
    private elementHelper: FormElementHelper;
    private dateUtil: DateUtil;

    constructor(){
        this.elementHelper = new FormElementHelper();
        this.dateUtil = new DateUtil();
    }

    /**
     * Build portal data
     *
     * @param {IForm[]} forms.
     * @return {any} Forms.
     */
    public buildPortalData = (forms: IForm[]): any=>{
        return forms.map((form: IForm)=>({
            id: form.id,
            title: form.title,
            groups: form.groups.map((group: IFormGroup)=>{
                return {
                    id: group.id,
                    title: group.title,
                    elements: group.elements.map((formElement: IFormElement)=> {
                        let isNullValue = false;
                        if(!_.isNull(formElement.depends_on)){
                            const Element: any = this.elementHelper.findFormElement(forms, formElement.depends_on);

                            if(!_.isNull(Element)){
                                let eleVal = this.getElementValue(Element);
                                
                                eleVal = _.isNull(eleVal) ? "" : (eleVal || "").toString().toLowerCase();
                                const Dependentvalues = _.toLower(formElement.dependent_values || "").toString().trim();
                                if(_.isEqual(eleVal.length, 0)){
                                    isNullValue = true;
                                }
                                isNullValue = _.isEqual(Dependentvalues.indexOf(eleVal), -1);
                            }
                        }
                        if(isNullValue){
                            return {
                                id: formElement.id,
                                typeId: formElement.type_id,
                                labelId: formElement.labelId,
                                labelName: formElement.labelName,
                                value : null,
                                formElementAuth: formElement.form_element_auth,
                                isPassword: formElement.is_password
                            };
                        }
                        else if(formElement.is_email && 
                            _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)){
                            const Emails = (formElement.value || "").replaceAll(/,/gi, ";").split(";")
                                .filter((email: string) => {
                                    const Email = (email || "").toString().trim();
                                    return !_.isEqual(Email.length, 0);
                                }).map((email: string)=>{
                                    return (email || "").toString().trim();
                                });
                            return {
                                id: formElement.id,
                                typeId: formElement.type_id,
                                labelId: formElement.labelId,
                                labelName: formElement.labelName,
                                value: Emails.join(";"),
                                formElementAuth: formElement.form_element_auth,
                                isPassword: formElement.is_password
                            };
                        }

                        let elementValue;

                        if(!_.isNull(formElement.form_element_auth)){
                            elementValue = _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.FILE_INPUT) ? null :
                            this.getElementValueForFormSubmit(formElement);
                        }else{
                            elementValue = this.getElementValueForFormSubmit(formElement);
                        }

                        return {
                            id: formElement.id,
                            typeId: formElement.type_id,
                            labelId: formElement.labelId,
                            labelName: formElement.labelName,
                            value: elementValue,
                            formElementAuth: formElement.form_element_auth,
                            isPassword: formElement.is_password
                        };
                    })
                };
            })
        }));
    }

    /**
     * Get select element value
     *
     * @param {IFormElement} formElement.
     * @return {any}.
     */
    getSelectElementValue = (formElement: IFormElement): any =>{
        if(formElement.is_multi){
            return !_.isNull(formElement.value) ? (formElement.value || []).map((value: IOption)=> value.value).join(",") : null;
        }
        return !_.isNull(formElement.value) ? formElement.value.value : null;
    }

    /**
     * Get checkbox element value
     *
     * @param {IFormElement} formElement.
     * @return {any}.
     */
    getCheckboxValue = (formElement: IFormElement): any =>{
        const IsAll: any = (formElement.value || "").indexOf(UtilHelper.allOptionValue);
        if(!_.isEqual(IsAll, -1)){
            return UtilHelper.allOptionValue;
        }
        return formElement.value;
    }

    /**
     * Get form element value
     *
     * @param {IFormElement} formElement.
     * @return {IForm[]} Forms.
     */
    private getElementValue = (formElement: IFormElement): any =>{
        let elementValue = formElement.value;
        switch(formElement.type_id){
        case UI_ELEMENT_TYPES.TEXT_INPUT:
        case UI_ELEMENT_TYPES.RADIO_INPUT:
        case UI_ELEMENT_TYPES.SWITCH_INPUT:
            elementValue = formElement.value;
            break;
        case UI_ELEMENT_TYPES.DATERANGE_INPUT:
            elementValue = _.isNull(formElement.value) ? null : `${formElement.value[0]} - ${formElement.value[1]}`;
            break;
        case UI_ELEMENT_TYPES.SELECT_INPUT:
            elementValue = this.getSelectElementValue(formElement);
            break;
        case UI_ELEMENT_TYPES.CHECKBOX_INPUT: {
            elementValue = this.getCheckboxValue(formElement);
            break;
        }
        case UI_ELEMENT_TYPES.FILE_INPUT:
            elementValue = _.isNull(formElement.value) || _.isEmpty(formElement.value.acceptedFiles) ? null : formElement.value.acceptedFiles; 
            break;           
        
        case UI_ELEMENT_TYPES.GRID:
            elementValue = _.isNull(formElement.value) ? null : formElement.value;
            break;           
        }
        return elementValue;
    }

    /**
     * Get select element value for submit form
     *
     * @param {IFormElement} formElement.
     * @return {IForm[]} Forms.
     */
    getSelectSubmitValue = (formElement: IFormElement): any =>{
        if(formElement.is_multi){
            return !_.isNull(formElement.value) ? (formElement.value || []) : null;

        }
        return !_.isNull(formElement.value) ? formElement.value : null;
    }

    /**
     * Get checkbox element value for submit form
     *
     * @param {IFormElement} formElement.
     * @return {IForm[]} Forms.
     */
    getCheckboxSubmitValue = (formElement: IFormElement): any =>{
        const IsAll = (formElement.value || "").indexOf(UtilHelper.allOptionValue);

         if(!_.isEqual(IsAll, -1)){
             return  UtilHelper.allOptionValue;
         }
                
         return formElement.value;
    }


     /**
     * Get form element value for submit form
     *
     * @param {IFormElement} formElement.
     * @return {IForm[]} Forms.
     */
     private getElementValueForFormSubmit = (formElement: IFormElement): any =>{
         let elementValue = formElement.value;
         switch(formElement.type_id){
         case UI_ELEMENT_TYPES.TEXT_INPUT:
        case UI_ELEMENT_TYPES.RADIO_INPUT:
        case UI_ELEMENT_TYPES.SWITCH_INPUT:
             elementValue = formElement.value;
             break;
         case UI_ELEMENT_TYPES.DATERANGE_INPUT:
             elementValue = _.isNull(formElement.value) ? null : `${formElement.value[0]} - ${formElement.value[1]}`;
             break;
         case UI_ELEMENT_TYPES.SELECT_INPUT:
             elementValue = this.getSelectSubmitValue(formElement);
             break;
         case UI_ELEMENT_TYPES.CHECKBOX_INPUT: {
             elementValue = this.getCheckboxSubmitValue(formElement);
             break;
         }
         case UI_ELEMENT_TYPES.FILE_INPUT:
             elementValue = _.isNull(formElement.value) || _.isEmpty(formElement.value.acceptedFiles) ? null : formElement.value.acceptedFiles;      
             break;      
         
         case UI_ELEMENT_TYPES.GRID:
            elementValue = _.isNull(formElement.value) ? null : formElement.value;
            break;  
        }   
         return elementValue;
     }

    /**
     * Get file elements
     *
     * @param {IForm[]} forms.
     * @return {any}.
     */
    getFileElements = (forms: IForm[]): any=>{
        const filesData: any = [];
        forms.forEach((form: IForm)=>(
            form.groups.forEach((group: IFormGroup)=>{
                group.elements.forEach((formElement: IFormElement)=> {
                    if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.FILE_INPUT)
                    && formElement.is_visible) {
                        filesData.push({
                            label: _.toLower(formElement.labelId),
                            value: this.getElementValue(formElement)
                        });
                    }
                });
            })
        ));
        return filesData;
    }

    /**
     * Check the form for file element
     *
     * @param {IFormElement} formElement.
     * @return {boolean}.
     */
    isFileElementExists = (forms: IForm[]): boolean=>{
        for(const form of forms){
            for(const formGroup of form.groups){
                for(const formElement of formGroup.elements){
                    if(_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.FILE_INPUT)
                    && !formElement.load_data_to_grid){
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /**
     * Get form data
     *
     * @param {IFormElement} formElement.
     * @return {any} formData.
     */
    buildFormData = (forms:IForm[]): any=>{
        const formData:any = {};
        const encryptedKeys: string[] = [];

        forms.forEach((form: IForm)=>{
            form.groups.forEach((formGroup: IFormGroup)=>{

                formGroup.elements.forEach((formElement: IFormElement)=>{
                    const FormElement = formElement;

                    if(_.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)
                    && FormElement.is_password){
                        encryptedKeys.push(_.toLower(FormElement.labelId));
                    }

                    if(!_.isNull(FormElement.form_element_auth)){
                        if(FormElement.form_element_auth.in_header) {
                            return;
                        }
                    }
                    let isNullValue = false;
                    if(!_.isNull(FormElement.depends_on)){
                        const Element: any = this.elementHelper.findFormElement(forms, FormElement.depends_on);

                        if(!_.isNull(Element)){
                            let elementValue = this.getElementValue(Element);
                         
                            elementValue = _.isNull(elementValue) ? "" : (elementValue || "").toString().toLowerCase();
                            const Dependentvalues = _.toLower(FormElement.dependent_values || "").toString().trim();
                            if(_.isEqual(elementValue.length, 0)){
                                isNullValue = true;
                            }
                            isNullValue = _.isEqual(Dependentvalues.indexOf(elementValue), -1);
                        }
                    }
                    if(isNullValue){
                        formData[_.toLower(FormElement.labelId)] = null;
                    }
                    else if(FormElement.is_email
                        && _.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)){
                        const Emails = (FormElement.value || "").replaceAll(/,/gi, ";").split(";")
                            .filter((email: string) => {
                                const Email = (email || "").toString().trim();
                                return !_.isEqual(Email.length, 0);
                            }).map((email: string)=>{
                                return (email || "").toString().trim();
                            });
                        formData[_.toLower(FormElement.labelId)] = Emails.join(";");
                    } else if (!_.isNull(FormElement.dynamic_configuration)
                    && _.isEqual(FormElement.dynamic_configuration.elementType, UI_DYNAMIC_CONFIG_ELEMENT_TYPES.METRICS)){
                        const DynamicFieldValues = (FormElement.dynamic_fields_values || []);
                        const DynamicData = DynamicFieldValues.map((data: any)=>({
                                label: _.isNull(data.value) ? null : data.value.label,
                                value: _.isNull(data.value) ? null : ElementDS.getOrderValue(data.sortby)
                            }));
                        
                        formData[_.toLower(FormElement.labelId)] = DynamicData;
                    }
                    else if(_.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.FILE_INPUT)){
                        formData[_.toLower(FormElement.labelId)] = null;
                        if(FormElement.load_data_to_grid){
                            const Data = FormElement.dynamic_fields_values.map((data: any) => _.omit(data, ["id", "isNewRow"]));
                            if(_.isEqual(FormElement.dynamic_configuration.elementOutput, "columnArray")){
                                const FormData: any = {};
                                FormElement.dynamic_configuration.headerData.filter((header: IGridHeader) => !header.hide).forEach((header: IGridHeader) =>{
                                    FormData[header.field] = Data.map((data:any) => {

                                        for(const key in data){
                                            if(_.isEqual(key, header.field)){
                                                return data[key];
                                            }
                                        }
                                    });
                                });
                                formData[_.toLower(FormElement.labelId)] = FormData;
                            }else{
                                formData[_.toLower(FormElement.labelId)] = Data;
                            }
                            
                        }else if(!_.isNull(FormElement.value)){
                            const Files = (_.isArray(FormElement.value.acceptedFiles) ? FormElement.value.acceptedFiles : [])
                            .filter((file: IFileUpload) => !_.isEqual(file.status, "error")).map((file: IFileUpload)=> ({
                                signedURL: file.signedURL,
                                type: file.type,
                                name: file.name,
                                size: file.size,
                                displaySize: file.displaySize,
                                fileInputText: file.fileInputText
                            }));

                            if(!_.isEmpty(Files)){
                                formData[_.toLower(FormElement.labelId)] = FormElement.is_multi ? Files : Files[0];
                            }
                        }
                
                    } else if(_.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)){
                        const APIValueURL = formElement.api_value_url || "";

                        if(!_.isEmpty(APIValueURL)){
                            formData[_.toLower(FormElement.labelId)] = this.buildSelectValueData(formElement);
                            }
                        else if(FormElement.select_output_onlyvalue){
                            if(FormElement.is_multi){
                                const Values = FormElement.value || [];
                                formData[_.toLower(FormElement.labelId)] = Values.map((option: IOption) => option.value);
                            }
                            else{
                                formData[_.toLower(FormElement.labelId)] = _.isNull(FormElement.value) ? null : FormElement.value.value;
                            }
                        }else{
                            if(FormElement.is_multi){
                                const Values = FormElement.value || [];
                                const IsAll = Values.find((option: IOption) => _.isEqual(option.value, UtilHelper.allOptionValue));

                                formData[_.toLower(FormElement.labelId)] = !_.isUndefined(IsAll) ? 
                                    FormElement.dataSource.filter((option: IOption) => !_.isEqual(option.value, UtilHelper.allOptionValue)) 
                                    : Values;
                            }
                            else{
                                formData[_.toLower(FormElement.labelId)] = FormElement.value;
                            }
                        }
                    }
                    else if(_.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.CHECKBOX_INPUT)){
                        const Value = _.isEqual((FormElement.value || "").toString().trim(), 0) ? null : FormElement.value;
                        if(!_.isNull(Value)){
                            const IsAll = _.isEqual(_.trim(FormElement.value.split(",")[0]), UtilHelper.allOptionValue);
                            formData[_.toLower(FormElement.labelId)] = IsAll ? FormElement.dataSource.filter((option: IOption) => !_.isEqual(option.value, UtilHelper.allOptionValue)).map((option: IOption) => option.value).join(",")
                             : FormElement.value;
                        }else{
                            formData[_.toLower(FormElement.labelId)] = FormElement.value;
                        }
                    } 
                    else if(_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DATERANGE_INPUT)){
                        formData[_.toLower(FormElement.labelId)] = this.getDateRangeValues(FormElement);
                    }
                    else if(_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DOWNLOAD)){
                        formData[_.toLower(FormElement.labelId)] = this.buildDownloadElementData(formElement);
                    }
                    else if(_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD)){
                        const ElementValue = !_.isNull(FormElement.value) ?  FormElement.value : null;

                        let file = {};
                        if(!_.isNull(ElementValue)
                        && !_.isNull(ElementValue.file)){
                            const File =  ElementValue.file;
                            file= {
                                publicURL: File.publicURL,
                                type: File.type,
                                name: File.name,
                                size: File.size,
                                displaySize: File.displaySize
                            };
                        }
                        formData[_.toLower(FormElement.labelId)] = {
                            file,
                            text: !_.isNull(ElementValue) ? ElementValue.text : ""
                        };
                    }
                    else if(_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.GRID)){
                             formElement.value = formElement.dataSource;
                            const Data = FormElement.dataSource.map((data: any) => _.omit(data, ["id", "isNewRow"]));
                            const dd:any = FormElement?.customConfiguration?.grid;
                            if(_.isEqual(dd?.elementOutput, "columnArray")){
                                const FormData: any = {};
                                dd?.headerData.filter((header: IGridHeader) => !header.hide).forEach((header: IGridHeader) =>{
                                    FormData[header.field] = Data.map((data:any) => {
                                        for(const key in data){
                                            if(_.isEqual(key, header?.field)){
                                                return data[key];
                                            }
                                        }
                                    });
                                });
                                formData[_.toLower(FormElement.labelId)] = FormData;
                            }else{
                                formData[_.toLower(FormElement.labelId)] = Data;
                            }
                    }
                    else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.ADD_BUTTON)) {                 
                        const formElementsArray = [];                    
                        if (!_.isNull(formElement)) {
                            const configurations = formGroup.elements;
                            const adNames = _.filter(configurations, { labelId: "ad_name" });
                            const authCodes = _.filter(configurations, { labelId: "auth_code" });
                            const pairedConfigs = adNames.map((adConfig: any, index: number) => {
                                const authCodeConfig = authCodes[index];
                                if (authCodeConfig) {
                                    return {
                                        ad_name: adConfig.value,
                                        auth_code: authCodeConfig.value
                                    };
                                }
                                return null;
                            }).filter(pair => !_.isNull(pair));
                    
                            if (!_.isEmpty(pairedConfigs)) {
                                formElementsArray.push(...pairedConfigs);
                            }
                        }
                        if (!_.isEmpty(formElementsArray)) {
                            formData["ad_credentials"] = formElementsArray;
                        }                
                    }
                    else{
                        const Value = _.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)
                            && _.isEqual(formElement.is_visible, false) && _.isEqual(formElement.isValid, true)
                            && !_.isEqual(formElement.depends_on, null) ? formElement.value = "" : FormElement.value;

                        let elementValue = Value;
                        if(!_.isNull(FormElement.form_element_auth)){
                            elementValue = _.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.FILE_INPUT) ? null : Value;
                        }
                        formData[_.toLower(FormElement.labelId)] = elementValue;
                    }
                });
            });
        });

        return {
            formData,
            encryptedKeys
        };
    }

    buildSelectValueData = (formElement: IFormElement): any =>{
        let selectedValue = formElement.value || null;
        if(formElement.select_output_onlyvalue){
            if(formElement.is_multi){
                const Values = formElement.value || [];
                selectedValue = Values.map((option: IOption) => option.value);
            }
            else{
                selectedValue = _.isNull(formElement.value) ? null : formElement.value.value;
            }
        }

        if(formElement.is_multi){
            const added:any[] = [];
            const removed:any[] = [];
            const OptionValues = formElement.value.map((option: IOption)=> option.value);
            const DSValue = formElement.valueDataSource || [];
            DSValue.forEach((option: IOption)=>{
                if(_.isEqual(OptionValues.indexOf(option.value), -1)){
                    removed.push(option);
                }
            });

            const ValueDS = DSValue.map((option: IOption)=> option.value);
            
            formElement.value.forEach((option: IOption)=>{
                if(_.isEqual(ValueDS.indexOf(option.value), -1)){
                    added.push(option);
                }
            });

            return {
                value: selectedValue,
                dataSource: DSValue,
                added,
                removed
            };
        }

        return selectedValue;
    }

    buildDownloadElementData = (formElement: IFormElement): any =>{
        const Value:any = formElement.value;

        if(!_.isObject(Value)){
            return null;
        }

        const ObjectKeys: string[] =["fileName", "title", "url"];
        let isValid = true;

        const ElementValue: any  =Value;
        ObjectKeys.forEach((val: any)=>{
            if(_.isUndefined(ElementValue[val])){
                isValid = isValid && false;
            }
        });
        if(!isValid){
            return null;
        }
        return Value;
    }

     /**
     * Get dateRange picker values
     *
     * @param {IFormElement[]} forms.
     * @return {any} ParentValues.
     */
    getDateRangeValues = (formElement: IFormElement): any =>{
        const ElementValue = (formElement.value || "").toString().trim();
        if(_.isEqual(ElementValue.length, 0)){
            return null;
        }

        const Range = !_.isEqual(ElementValue.length, 0) ? 
        ElementValue.split(",") : [];

        if(!_.isEqual(Range.length, 2)){
            return null;
        }
        const UpdatedRange = Range.map((date: string) => (date || "").toString().trim());

        const StartDate = this.dateUtil.formatDateValueFormSubmit(new Date(UpdatedRange[0]), formElement);
        const EndDate = this.dateUtil.formatDateValueFormSubmit(new Date(UpdatedRange[1]), formElement);
    
        return [StartDate, EndDate];
    }


    /**
     * Get headers for submit
     *
     * @param {IForm[]} forms.
     * @return {any} ParentValues.
     */
    getHeadersValueFromAuthElement = (forms: IForm[]): any => {
        const ParentValues:any = {};

        forms.forEach((form: IForm)=>{
            form.groups.forEach((formGroup: IFormGroup)=>{
                formGroup.elements.forEach((formElement: IFormElement)=>{
                    if(!_.isNull(formElement.form_element_auth)
                    && formElement.form_element_auth.in_header){
                        if(_.isEqual(formElement.form_element_auth.type, UI_ELEMENT_AUTH_TYPES.GOOGLE)){
                            ParentValues[UI_AUTH_HEADER_NAMES.GOOGLE] = formElement.value;
                        }
                        else if(_.isEqual(formElement.form_element_auth.type, UI_ELEMENT_AUTH_TYPES.FACEBOOK)){
                            ParentValues[UI_AUTH_HEADER_NAMES.FACEBOOK] = formElement.value;
                        }
                        else if(_.isEqual(formElement.form_element_auth.type, UI_ELEMENT_AUTH_TYPES.TIKTOK)){
                            ParentValues[UI_AUTH_HEADER_NAMES.TIKTOK] = formElement.value;
                        }
                    }
                });
            });
        });
        return ParentValues;
    }
}