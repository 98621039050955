import React from "react";
import { Button } from "@material-ui/core";
import classnames from "classnames";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import COLORS from "../../Theme/colors";
import { UIOutlineButton } from "../../Buttons";
import style from "./style";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  onClose: any;
  onSubmit: any;
  heading: string;
  body: string;
  submitBtnText: string;
  cancelBtnText: string;
  isDeleteAction: boolean;
};

export const ConfirmationDialog = (props: props): JSX.Element => {
  const {
    onSubmit,
    heading,
    body,
    submitBtnText,
    cancelBtnText,
    onClose,
    isDeleteAction,
  } = props;

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.overlay}></div>

      <div className={classes.dialog}>
        <div className={classes.closeButton} onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path color={COLORS.LIGHT_GRAY} fill="none" d="M0 0h24v24H0z" />
            <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        </div>
        <div className={classes.dialogContainer}>
          <div className={classes.dialogHeader}>{heading}</div>

          <div className={classes.dialogBody}>{body}</div>
          <div className={classes.dialogActions}>
            <UIOutlineButton
              cssStyle={{
                marginRight: "0.75rem",
              }}
              isOutline={false}
              onClick={onClose}
              btnText={cancelBtnText}
            />
            <Button
              variant="contained"
              color="primary"
              className={classnames({
                [classes.deleteButton]: isDeleteAction,
              })}
              onClick={onSubmit}
            >
              {submitBtnText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
